
import axios from 'axios'
import createAuthURL from 'services/utils/createAuthURL'
import tokenCache from 'services/cache/token'
import { FLASHCARD_API_ENDPOINT } from 'services/constants'
import jwtDecode from 'jwt-decode'

class BaseApi {
  constructor({
    authToken
  }) {
    const headers = {}
    if(authToken) {
      headers['Authorization'] = `Bearer ${authToken}`
    }
    this.request = axios.create({
      baseURL: FLASHCARD_API_ENDPOINT,
      timeout: 15000,
      headers,
    })
    this.request.interceptors.response.use(function (response) {
      return response
    }, (error) => {
      if (error.request.status === 401) {
        tokenCache.remove()
        this.setAuthToken({authToken: null})
        window.location = createAuthURL()
      }
      return Promise.reject(error)
    })
  }

  setAuthToken({
    authToken
  }) {
    this.request.defaults.headers.common['Authorization'] = `Bearer ${authToken}`
  }

  hasRole(role) {
    const token = this.request.defaults.headers.common['Authorization'] || tokenCache.get()
    try {
      const payload = jwtDecode(token)
      const {roles} = payload
      return roles.includes(role)
    } catch (error) {
      return false
    }
  }
}


export default BaseApi
