import {HUKOU_API_ENDPOINT, CALLBACK_PATH} from 'services/constants'
import history from 'services/history'

function createAuthURL() {
  return `${HUKOU_API_ENDPOINT}/sites/${CALLBACK_PATH}/login?next=${encodeURIComponent(`${window.location.origin}${history.createHref({
    ...history.location,
    search: `?token={token}`
  })}`)}`
}

export default createAuthURL
