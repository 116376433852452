
import BaseApi from './BaseApi'
import tokenCache from 'services/cache/token'

class CustomerApi extends BaseApi {
  async getAll({page=1,field,content}) {
    const res = await this.request.get("/customers", {
      params: {
        page,
        field,
        content
      }
    })
    return res.data
  }

  async getOne(id) {
    const res = await this.request.get(`/customers/${id}`)
    return res.data
  }

  async getUsers() {
    const res = await this.request.get(`/users`)
    return res.data
  }

  async addShop({
    url,
    customerId
  }) {
    let route = '/customers/new'
    if(customerId){
      route = `/customers/${customerId}/shops/new`
    }
    const res = await this.request.post(route, {
      url
    })
    return res.data
  }

  async updateUsers({
    users,
    customerId
  }) {
    const route = `/customers/${customerId}/users`
    const res = await this.request.put(route, {
      users: users.map(u=>{
        return {
          id: u.id
        }
      }),
      customerId
    })
    return res.data
  }

  async getIssues(params) {
    const { customerId } = params
    const res = await this.request.get(`/customers/${customerId}/issues`, {
      params
    })
    return res.data
  }

  async closeIssue(id) {
    const res = await this.request.put(`/issues/${id}/close`)
    return res.data
  }

  async fetchNeuronUrl(sid) {
    const res = await this.request.get(`/shops/${sid}/neuron`)
    return res.data

  }

  async fetchTranscriptUrl(sid) {
    const res = await this.request.get(`/shops/${sid}/transcript`)
    return res.data
  }

  async fetchLxxUrl(sid) {
    const res = await this.request.get(`/shops/${sid}/lxx`)
    return res.data
  }

  async fetchFailCases({
    sid,
    start_date,
    end_date
  }) {
    const res = await this.request.post(`shops/${sid}/fail_case_stats.xlsx`, {
      start_date,
      end_date
    })
    return res.data
  }

  async fetchQrStats({
    start_date,
    end_date,
    sid
  }) {
    const res = await this.request.get(`qr_stats`, {
      params: {
        start_date,
        end_date,
        sid
      }
    })
    return res.data
  }

  async fetchQrStatsExcel({
    start_date,
    end_date,
    sid
  }) {
    const res = await this.request.post(`qr_stats.xlsx`, {
      start_date,
      end_date,
      sid
    })
    return res.data
  }

}

export default new CustomerApi({authToken:tokenCache.get()})
