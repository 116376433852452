
import BaseApi from './BaseApi'
import tokenCache from 'services/cache/token'

class DemoApi extends BaseApi {
  getAll() {
    return this.request.get("/demos")
  }

  /**
   * @param {string} shopId shopId
   */
  async getVisitor(shopId) {
    const { data } = await this.request.post(`/demos/${shopId}`)
    return data
  }
}


export default new DemoApi({authToken:tokenCache.get()})
