export const APP_NAME = process.env.REACT_APP_NAME
export const APP_VERSION = process.env.REACT_APP_VERSION
export const APP_DESCRIPTION = process.env.REACT_APP_DESCRIPTION
export const APP_SHA = process.env.REACT_APP_SHA || Date.now()

export const WEBSITE_NAME = process.env.REACT_APP_WEBSITE_NAME

export const FLASHCARD_API_ENDPOINT = process.env.REACT_APP_FLASHCARD_API_ENDPOINT
export const FLASHCARD_SHORTCUT_ENDPOINT = process.env.REACT_APP_FLASHCARD_SHORTCUT_ENDPOINT
export const HUKOU_API_ENDPOINT = process.env.REACT_APP_HUKOU_API_ENDPOINT
export const CALLBACK_PATH = process.env.REACT_APP_CALLBACK_PATH

export const REDMINE = {
  ONE_WEEK_AGO: 1,
  ONE_MONTH_AGO: 2,
  NO_TIME_LIMIT: 3,
  START_DATE: 4,
  CLOSE_DATE: 5,
  STATUS_ID: 'status_id',

  IN_PROGRESS: 2,
  INVALID: 12,
  FIXED: 4,
  RESOLVED: 3,
  NEW: 1,
  CLOSED: 5,

  INTENT_BUG: 9,
  SLOT_BUG: 8,
  QUERY: 16,
  EXPECTED_INTENT: 15,
  CURRENT_INTENT: 11,
  IMAGES: 25
}

export const BASE_PAGINATION = {
  showQuickJumper: true,
  showTotal(total) {
    return `共 ${total} 项`
  }
}

export const BUG_URL_PREFIX = 'http://prism-snapshot.oss-cn-shanghai.aliyuncs.com'
