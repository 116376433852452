class Cache {
  constructor(key, storage) {
    this._key = key
    this._storage = storage
  }

  get key() {
    return this._key
  }

  get storage(){
    return this._storage
  }

  setStorage(storage) {
    this._storage = storage
  }

  getItem(key) {
    return this._storage.getItem(key)
  }

  setItem(key, value) {
    return this._storage.setItem(key, value)
  }

  removeItem(key) {
    return this._storage.removeItem(key)
  }

  get() {
    return this.getItem(this._key)
  }

  set(value) {
    return this.setItem(this._key, value)
  }

  remove() {
    return this.removeItem(this._key)
  }
}

export default Cache
